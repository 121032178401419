import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components/macro";
import Layout from "../components/Layout";
import { Link, navigate } from "gatsby";
import { lgBtnClick, getIdToken } from "../common/firebaseAnalytics";

const WelcomeSplash = () => {
  const [postcode, setPostcode] = useState(null);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const setID = async () => {
      localStorage.setItem("idToken", await getIdToken());
    };
    setID();
  }, []);

  const handlePostcode = (event) => {
    const postcode = event.target.value;
    if (postcode.length < 5) setPostcode(postcode);
    if (postcode.length === 4) {
      event.target.blur();
      localStorage.setItem("welcomeCompleted", true);
      localStorage.setItem("postcode", postcode);
      setCompleted(true);
    }
  };

  const handleDecline = () => {
    localStorage.setItem("welcomeCompleted", true);
    lgBtnClick("welcome_reject");
  };

  useEffect(() => {
    if (completed) {
      setTimeout(() => navigate("/"), 1000);
    }
  }, [completed]);

  return (
    <Layout>
      <Splash>
        <Title hide={completed}>Welcome!</Title>
        <Thanks hide={!completed}>Thanks</Thanks>
        <Description hide={completed}>
          Takeitaway showcases the creative ways our favourite dining haunts are
          adapting during the Covid 19 clusterf*ck.
        </Description>
        <Description hide={completed}>
          Sharing your postcode helps businesses better understand how to
          connect and serve you during this time.
        </Description>
        <InputLabel hide={completed}>Your Postcode</InputLabel>
        <PostcodeInput
          hide={completed}
          onChange={handlePostcode}
          value={postcode || ""}></PostcodeInput>
        <DeclineOption href="/" hide={completed} onClick={handleDecline}>
          Not right now
        </DeclineOption>
      </Splash>
    </Layout>
  );
};

export default WelcomeSplash;

const fadein = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeout = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const Splash = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: pink;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;

  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  color: white;
  opacity: 0;
  display: ${(props) => (props.hide ? "none" : "block")};
  animation-name: ${fadein};
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;

const Thanks = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  color: white;
  opacity: 0;
  display: ${(props) => (props.hide ? "none" : "block")};
  animation-name: ${(props) => (props.hide ? fadeout : fadein)};
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;

const Description = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  color: white;
  max-width: 600px;
  text-align: center;
  margin-bottom: 20px;
  opacity: 0;
  display: ${(props) => (props.hide ? "none" : "block")};
  animation-name: ${(props) => (props.hide ? fadeout : fadein)};
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: ${(props) => (props.hide ? "none" : "forwards")};

  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InputLabel = styled.label`
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  display: ${(props) => (props.hide ? "none" : "block")};
  animation-name: ${(props) => (props.hide ? fadeout : fadein)};
  opacity: 0;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;

const PostcodeInput = styled.input.attrs({
  type: "number",
  inputmode: "numeric",
  pattern: "[0-9]*",
})`
  outline: none;
  border: none;
  background-color: white;
  font-size: 1.6rem;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  width: 6em;
  opacity: 0;
  animation-name: ${(props) => (props.hide ? fadeout : fadein)};
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const DeclineOption = styled(Link)`
  font-size: 1.4rem;
  color: grey;
  opacity: 0;
  display: ${(props) => (props.hide ? "none" : "block")};
  animation-name: ${(props) => (props.hide ? fadeout : fadein)};
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;
